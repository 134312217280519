import React, { Component } from "react"

import styled from 'styled-components';

const AccordionWrapper = styled.div`
    margin: 2rem 0;
`;

const AccordionHeader = styled.button`
    background-color: #eee;
    color: #444;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;

    h3 {
        margin: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;  

        img {
            margin: 0;
            padding: 0;
        }
    }

    &.active, &:hover {
        background: #ddd;
    }
`;

const AccordionContent = styled.div`
    padding: 1.53rem;
    background: #f4f4f4;

    display: none;

    &.active {
        display: block;
    }

`;

class Accordion extends Component {
    state = {
        isContentShown: false,
    };

    componentDidMount()
    {
        this.setState(() => ({isContentShown: false}));
    }

    handleClick = () => {
        console.log("CLICK");
        const buttons = document.getElementsByClassName('ab');
        for(var i=0; i < buttons.length; i++)
        {
            buttons[i].classList.remove("active");
        }

        const boxes = document.getElementsByClassName('ac');
        for(i=0; i < boxes.length; i++)
        {
            boxes[i].classList.remove("active");
        }

        this.setState(state => ({ isContentShown: !state.isContentShown }));

    };

    render() {
        const { header, image, children } = this.props;
        return (
            <AccordionWrapper>
                <AccordionHeader  className={(this.state.isContentShown) ? 'ab active' : 'ab'} onClick={this.handleClick}>
                    <h3>
                        {(image != null) && <img src={image} alt={header} />}
                        {header}
                    </h3>
                </AccordionHeader>
                <AccordionContent className={(this.state.isContentShown) ? 'ac active' : 'ac'}>
                    {children}
                </AccordionContent>
            </AccordionWrapper>
        )
    }
}
/*
MainMenu.propTypes = {
    logo: PropTypes.node.isRequired,
}

MainMenu.defaultProps = {
    allwaysTop: false,
}
*/
export default Accordion