import React from "react"

import { IconContext } from "react-icons";
import { FaPhone, FaFax, FaMailBulk, FaRegWindowMaximize, FaAtlas } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BGSlideshow from "../components/bg_slideshow"
import Accordion from "../components/accordion"

import styled from 'styled-components';
import device from '../utils/media';

import styles from "./index.module.scss"

import SL1 from "../images/12132616_897522180295513_2403437913656357964_o.jpg"
import SL2 from "../images/467665_320827107965026_945010742_o.jpg"
import SL3 from "../images/20160907_180128.jpg"
import SL4 from "../images/20170913_134523.jpg"
import SL5 from "../images/20170914_173932.jpg"
import SL6 from "../images/65569479_2309234605790923_8219389329374445568_o.jpg"
import SL7 from "../images/61520003_161076254931975_1245127992810051582_n.jpg"

import Map from "../images/anfahrt.gif"

const FlexDiv = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    @media ${device.tablet} {
        flex-direction: row;
        padding: 3em;
    }
`;

export default () => (
    <Layout allwaysTop={false} active="/kontakt">
        <SEO title="Kontakt" />
        <BGSlideshow style={{zIndex:-1}} entries={
            [
                {bgImage: SL1, animationDelay: 0, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL2, animationDelay: 6, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL3, animationDelay: 12, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL4, animationDelay: 18, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL5, animationDelay: 24, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL6, animationDelay: 30, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL7, animationDelay: 36, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
            ]} />

        <article style={{background:'white'}}>
        <IconContext.Provider value={{size: "1.25em", style: { verticalAlign: 'middle'}}}>
        <div className={styles.content_div} style={{marginTop: '100vh'}}>
        <h2>So finden Sie uns!</h2>
                <FlexDiv>
                    <div style={{ maxWidth: '60%'}}>
                        <img style={{padding: '1rem'}} src={Map} alt="Karte" />
                    </div>
                    <div >
                        <FaAtlas /><br />
                        Schwertransport Lau GmbH &amp; Co. KG<br/>
                        Sandkrug 6<br />
                        D - 18059 Papendorf b. Rostock<br />
                    </div>
                </FlexDiv>
                <div>
                    <Accordion header="Anfahrt aus Richtung Berlin (A19)">
                        <ul>
                            <li>fahren Sie die A19 bis zum Kreuz A20</li>
                            <li>biegen Sie jetzt auf die A20 in Richtung Lübeck </li>
                            <li>gleich die erste Abfahrt (Rostock-Südstadt) wieder runter </li>
                            <li>weiter Richtung Rostock (L132) </li>
                            <li>achten Sie auf den Abzweig nach Papendorf</li> 
                            <li>wenn Sie rechts abgebogen sind, gleich wieder rechts (50m), Sie finden uns 100m weiter auf der rechten Seite</li>

                        </ul>
                    </Accordion>
                    <Accordion header="Anfahrt aus Richtung Rostock">
                        <ul>
                            <li>fahren Sie über die Südstadt Richtung A20 (Bützow + Schwaan)</li>
                            <li>haben Sie Rostock hinter sich gelassen, achten Sie bitte auf den Abzweig nach Papendorf (linke Seite)</li>
                            <li>wenn Sie links abgebogen sind, 50m rechts, Sie finden uns 100m weiter auf der rechten Seite</li>
                        </ul>
 
                    </Accordion>
                    <Accordion header="Anfahrt aus Richtung Hamburg oder Stettin (A20)">
                        <ul>
                            <li>verlassen Sie die Autobahn auf der Abfahrt Rostock-Südstadt</li>
                            <li>weiter Richtung Rostock (L132) </li>
                            <li>achten Sie auf den Abzweig nach Papendorf</li> 
                            <li>wenn Sie rechts abgebogen sind, gleich wieder rechts (50m), Sie finden uns 100m weiter auf der rechten Seite</li>

                        </ul>
                    </Accordion>
                </div>
            </div>

        <div  style={{background: '#efefef'}}>
            <div className={styles.content_div}>
            <h2>So treten Sie mit uns in Kontakt</h2>
            <FlexDiv>

                    <div >
                        <FaRegWindowMaximize />&nbsp;&nbsp;&nbsp;<a href="/">www.lau-rostock.de</a><br />
                        <FaMailBulk />&nbsp;&nbsp;&nbsp;<a href="mailto:info@lau-rostock.de" >info@lau-rostock.de</a><br />
                    </div>
                    <div>
                        <FaPhone />&nbsp;&nbsp;&nbsp;<a href="tel:+49381444870">+49 381 4444 87 0</a><br />
                        <FaFax />&nbsp;&nbsp;&nbsp;+49 381 4444 87 22<br />
                    </div>
            </FlexDiv>
            <div>
                <p>Wir freuen uns auf Ihre Nachricht!</p>
            </div>
        </div>
        </div>
        </IconContext.Provider>
        </article>
    </Layout>
)